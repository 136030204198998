import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="קטע מספר 7: מסכר רעים לגשר רעים">
      <SectionContent title="קטע מספר 7: מסכר רעים לגשר רעים">
        <div>
          <p>
            <strong>אורך המקטע:</strong> 8.75 קמ
          </p>
          <p>
            {" "}
            <strong>רמת קושי:</strong> קלה{" "}
          </p>
          <p>
            {" "}
            <strong>תיאור הקטע:</strong> סכר ל גשר רעים.{" "}
          </p>
          <p>
            מתחנת ההחלפה, חוצים מערבה, במקביל ל צינור הירוק, כ 1 קמ עד לנק
            החיבור עם דרך הבשור, בגדה המערבית, בדרך הבשור פונים צפונה. ורצים על
            מסלול דרך הבשור, המסלול עובר מערבית ובשדות החקלאיים, מערבית ל מאגר
            נירים. ומזרחית מ אוגדת עזה. בהמשך, מגיעים ממערב למפגש נחל הבשור, עם
            גשר רעים. התחנה מתחת לגשר רעים.
          </p>
          <p>
            <strong>טופוגרפיה:</strong> דרך לבנה, בעיקר מישורית.
          </p>
          <p>
            <strong>בטיחות:</strong> אין מעבר או חציית כבישים. לאורך המסלול.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="https://www.alltrails.com/widget/map/map-a70145b--56?u=m"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
